@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animate-typewriter {
    @apply opacity-0;
    animation: typewriter 0.5s ease-in-out forwards;
  }
}
